$(function() {

    /* header bg */
    function headerBg() {
        const sTop = $(window).scrollTop();

        if (sTop > 1) {
            $("header").addClass("bg");
        } else {
            $("header").removeClass("bg");
        }
    };
    $(window).scroll(function() {
        headerBg();
    })
    $(document).ready(function() {
        headerBg();
    })

    /* video plugin */
    $('video').mediaelementplayer({
        hideVideoControlsOnLoad: true,
    });


    /* wow plugin */
    new WOW().init();


    /* number animation */
    var space = $.animateNumber.numberStepFactories.separator(' ');

    if ($('div').is('#customers')) {
        $(window).scroll(function () {
            var targetTop = $('#customers').offset().top,
                winHeight = $(window).height(),
                scrollToElem = targetTop - winHeight + 270,
                winScrollTop = $(this).scrollTop();

            if (winScrollTop > scrollToElem) {
                $('#customers').addClass('active');

                $('#progress-1').animateNumber({
                        number: 84,
                        numberStep: space
                    },
                    {
                        duration: 3000
                    }
                );
                $('#progress-2').animateNumber({
                        number: 85,
                        numberStep: space
                    },
                    {
                        duration: 3000
                    }
                );
                $('#progress-3').animateNumber({
                        number: 75,
                        numberStep: space
                    },
                    {
                        duration: 3000
                    }
                );
            }
        });
    }


    /* menu button - open mobile menu  */
    window.scrollHidden = function(comp) {
        if (!window.overlay || (window.overlay && comp == window.overlay)) {
            var coef = document.documentElement.scrollHeight - window.innerHeight,
                bcg = document.querySelector('.bcg-item');

            if (document.body.classList.contains('scroll-hidden')) {
                setTimeout(function () {
                    document.body.classList.remove('scroll-hidden');
                    document.body.style.marginRight = '0px';

                    if (bcg) {
                        bcg.style.width = '100%';
                    }
                }, bcg ? 350 : 10)

                window.overlay = false;
            } else {
                if (coef > 0) {
                    var offset = window.innerWidth - document.body.clientWidth;

                    document.body.style.marginRight = offset + 'px';

                    if (bcg) {
                        bcg.style.width = 'calc(100% - ' + offset + 'px)';
                    }
                }
                document.body.classList.add('scroll-hidden');
                window.overlay = comp;
            }
        }
    }
    $(window).resize(function () {
        if (window.innerWidth > 1280) {
            if ( $('.header').hasClass('active') ) {
                $('.header').removeClass('active');
                $('.overlay').fadeOut(300);
                scrollHidden();
            }
        }
    });
    $('.menu-btn').click(function () {
        $('.header').toggleClass('active');

        if ( $('.header').hasClass('active') ) {
            $('.overlay').fadeIn(300);
            scrollHidden();
        } else {
            $('.overlay').fadeOut(300);
            scrollHidden();
        }
    })
    $('.overlay').click(function () {
        if ( $('.header').hasClass('active') ) {
            $('.header').removeClass('active');
            $('.overlay').fadeOut(300);
            scrollHidden();
        }
    })

})
